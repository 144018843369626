<template>
  <div>
    <div class="container-fluid" v-if="!loading">
      <div class="card border-0 shadow-none card-margin">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-4">
              <h4>Create treatment</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <label for="">Title</label>
                  <input
                    type="text"
                    v-model="treatment.title"
                    @input="titleUpdate"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Slug</label>
                  <input
                    type="text"
                    v-model="treatment.slug"
                    class="form-control"
                    disabled
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Subtitle</label>
                  <input
                    type="text"
                    v-model="treatment.sub_title"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Accounting code</label>
                  <input
                    type="text"
                    v-model="treatment.item_code"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Status</label>
                  <select v-model="treatment.status" @change="statusChange" class="form-control">
                    <option value="draft">Draft</option>
                    <option value="on_hold">On hold</option>
                    <option value="published">Published</option>
                  </select>
                </div>
                <div class="col">
                  <label for="">Retreat</label>
                  <select v-model="treatment.retreat" class="form-control">
                    <option value="uk">UK</option>
                    <option value="malta">Malta</option>
                    <option value="both">Both</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Price</label>
                  <input
                    type="number"
                    v-model="treatment.price"
                    @change="formatPrices"
                    step="0.01"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Euro price</label>
                  <input
                    type="number"
                    v-model="treatment.euro_price"
                    @change="formatPrices"
                    step="0.01"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="">Credits cost</label>
                  <input
                    type="number"
                    v-model="treatment.credits"
                    step="0.01"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for="">Duration in minutes</label>
                  <input
                    type="number"
                    v-model="treatment.duration"
                    step="0.01"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for="">Description</label>
                  <textarea
                    v-model="treatment.description"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for="image">Image</label>
                  <div class="upload" v-if="!treatment.image" @click="openImageUploadModal">
                    <i class="far fa-plus upload-icon" />
                  </div>
                  <div class="upload" v-if="treatment.image">
                    <img v-bind:src="treatment.image" v-on:click.self="unsetImage" class="form-control" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed bottom-0 mb-3 start-0 ms-2">
        <button
          class="btn btn-success btn-lg d-block mb-4"
          v-tooltip.right="'Save the treatment'"
          @click="saveTreatment"
        >
          <i class="far fa-save"></i>
        </button>

        <button
          class="btn btn-outline-success btn-lg"
          v-tooltip.right="'Back to treatments'"
          @click="$router.push('/treatments')"
        >
          <i class="far fa-arrow-left"></i>
        </button>
      </div>
    </div>
    <loading v-else></loading>

    <image-upload-modal
      @image="setImage"
    ></image-upload-modal>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import ImageUploadModal from "./ImageUploadModal.vue";

export default {
  data() {
    return {
      loading: false,
      treatment: {
        id: null,
        title: "",
        slug: "",
        item_code: "",
        sub_title: "",
        description: "",
        status: "draft",
        image: "",
        price: 0,
        euro_price: 0,
        credits: 0,
        duration: 0,
        retreat: ""
      }
    };
  },
  methods: {
    formatPrices() {
      this.treatment.price = parseFloat(this.treatment.price).toFixed(2);
      this.treatment.euro_price = parseFloat(this.treatment.euro_price).toFixed(2);
    },

    saveTreatment() {
      // @todo Really need to start using a proper validation lib,
      // but adding this as a hotfix.

     if (!this.treatment.title) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please fill out the title."
        });
        return;
      }

      if (!this.treatment.retreat) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please select the retreat."
        });
        return;
      }

      if (
        (this.treatment.retreat == "uk" && !parseInt(this.treatment.price))
        ||
        (this.treatment.retreat == "malta" && !parseInt(this.treatment.euro_price))
        ||
        (this.treatment.retreat == "both" && (!parseInt(this.treatment.price) || !parseInt(this.treatment.euro_price)))
      ) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please fill out the correct price for this treatment retreat type."
        });
        return;
      }

      this.loading = true;

      let price = parseFloat(this.treatment.price);
      let euro_price = parseFloat(this.treatment.euro_price);
      let credits = parseFloat(this.treatment.credits);
      let duration = parseFloat(this.treatment.duration);

      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/treatments",
          {
            id: this.treatment.id,
            title: this.treatment.title,
            slug: this.treatment.slug,
            item_code: this.treatment.item_code,
            sub_title: this.treatment.sub_title,
            description: this.treatment.description,
            status: this.treatment.status,
            image: this.treatment.image,
            price: price ? price : 0,
            euro_price: euro_price ? euro_price : 0,
            credits: credits ? credits : 0,
            duration: duration ? duration : 0,
            retreat: this.treatment.retreat
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);

          if (!data.color || data.color != "danger") {
            this.$router.push("/treatments/" + data.treatment_id);
          } else {
            this.loading = false;
          }
        });
    },

    // @todo Can we move this to a functions file?

    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, "");

      // Make the string lowercase
      str = str.toLowerCase();

      // Remove accents, swap ñ for n, etc
      var from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      // Remove invalid chars
      str = str
        .replace(/[^a-z0-9 -]/g, "")
        // Collapse whitespace and replace by -
        .replace(/\s+/g, "-")
        // Collapse dashes
        .replace(/-+/g, "-");

      return str;
    },

    statusChange() {
      if (this.treatment.status === 'published') {
        if (!this.treatment.image) {
          this.$EventBus.$emit("alert", {
            color: "danger", message: "Please upload an image before publishing."
          });
          this.treatment.status = 'draft';
          return;
        }

        var c = confirm(
          "You're about to publish this treatment. This will make it available on the site. Are you sure?");
        if (!c) {
          this.treatment.status = 'draft';
        }
      }
    },

    titleUpdate() {
      if (this.treatment.title != "") {
        this.treatment.slug = this.slugify(this.treatment.title);
      }
    },

    // @todo Image stuff, can we merge this with the wysiwyg component?

    openImageUploadModal() {
      $("#imageUploadModal").modal('show');
    },

    closeImageUploadModal() {
      $("#imageUploadModal").modal('hide');
    },

    setImage(image) {
      this.treatment.image = image;
      this.closeImageUploadModal();
    },
    unsetImage() {
      this.treatment.image = null;
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    Loading,
    ImageUploadModal
  },
};
</script>

<style>
  .card-margin {
    margin: 0 2rem;
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 125px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .upload:hover {
    border-color: #409eff;
  }

  .upload-icon {
    font-size: 2rem;
    color: #808a97;
  }

  .upload > img {
    max-width: 25%;
    border: 0px;
  }
</style>