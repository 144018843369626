<template>
  <div>
    <div class="modal fade" role="dialog" id="imageUploadModal">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document" id="imageUploadModalDialog">
        <div class="modal-content bg-white" id="imageUploadModalContent">

          <div class="modal-header">
            <h5 class="modal-title">
              Image upload
            </h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row mb-3">
              <div class="col">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  :disabled="uploading"
                  @change="upload"
                  class="form-control mb-3"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <button
                  class="btn btn-outline-primary float-end"
                  :disabled="uploading"
                >
                  <span v-if="uploading">
                    Uploading...
                  </span>
                  <span v-else>Upload</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        uploading: false
      };
    },
    methods: {
      upload(evt) {
        this.uploading = true;

        let formData = new FormData();
        formData.append("file", evt.target.files[0]);

        this.$axios
          .post(process.env.VUE_APP_API_URL + "/treatments/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(({ data }) => {
            this.$emit("image", data.path);
            this.uploading = false;
          });
      },
    },
  }
</script>

<style scoped>
  .modal-header {
    padding: 0rem 1rem;
  }

  .modal-body {
    padding: 0.5rem 1rem;
  }

  .close {
    background-color: #fff;
    border: 0px;
    font-size: 2rem;
  }
</style>